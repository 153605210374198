import { AppPermission, CLIENT_ID } from '../constants';

export const getAppPermissions = <T extends string>(InsightClaims: string, clientId: string) => {
    return InsightClaims.split(' ').filter(p => p.startsWith(clientId)) as AppPermission<T>[];
};

export const getUserInfoFromIdClaims = ({
    given_name,
    family_name,
    name,
    email,
    sub,
    InsightClaims,
}: Record<string, string>): app.UserInfo => {
    const permissions = getAppPermissions(InsightClaims, CLIENT_ID);
    const tsr = permissions.includes('reladyne.tsr');
    const csr = permissions.includes('reladyne.csr');
    const level2 = permissions.includes('reladyne.level2');
    const priceDesk = permissions.includes('reladyne.priceDesk');

    return {
        firstName: given_name,
        lastName: family_name,
        email,
        displayName: name,
        id: sub,
        insightClaims: InsightClaims,
        tsr,
        csr,
        level2,
        priceDesk,
    }
};
