import { I2PEnvironment } from '@price-for-profit/micro-services';
import { Dashboard } from '@mui/icons-material';

const routeConfig: EnvironmentNavigation[] = [
    {
        application: {
            applicationName: 'Drive Account',
            env: {
                local: {
                    applicationId: '4f22cec6-8489-487a-8919-50cdf8babfad',
                    deployedUrl: 'https://localhost:5001/apps/account',
                },
                dev: {
                    applicationId: '4f22cec6-8489-487a-8919-50cdf8babfad',
                    deployedUrl: 'https://reladyne.dev.insight2profit.com/apps/account',
                },
                sandbox: {
                    applicationId: 'e49b0393-4d0d-47dc-9487-5fe0361d3a20',
                    deployedUrl: 'https://reladyne.sandbox.insight2profit.com/apps/account',
                },
                prod: {
                    applicationId: 'a7a9e74a-8a30-4413-aa23-014677470fce',
                    deployedUrl: 'https://reladyne.insight2profit.com/apps/account',
                },
            },
        },
        links: [
            {
                title: 'Analytics Dashboard',
                url: '/analytics',
                icon: <Dashboard sx={{ color: 'primary.main' }} />,
            },
        ],
    },
    {
        application: {
            applicationName: 'Reladyne PMT',
            env: {
                local: {
                    applicationId: 'fe728d97-8987-46c9-a154-55f80bb1490d',
                    deployedUrl: 'https://localhost:5003/apps/pmt',
                },
                dev: {
                    applicationId: 'fe728d97-8987-46c9-a154-55f80bb1490d',
                    deployedUrl: 'https://reladyne.dev.insight2profit.com/apps/pmt',
                },
                sandbox: {
                    applicationId: '4c86331c-5aee-4df5-95ee-8261b82aca1b',
                    deployedUrl: 'https://reladyne.sandbox.insight2profit.com/apps/pmt',
                },
                prod: {
                    applicationId: '4cbf04b4-9d4a-4154-b329-b7a446e516ff',
                    deployedUrl: 'https://reladyne.insight2profit.com/apps/pmt',
                },
            },
        },
        links: [
            {
                title: 'Quote Dashboard',
                url: '/quote-dashboard',
                icon: <Dashboard sx={{ color: 'primary.main' }} />,
            },
        ],
    },
];

interface NavigationLink {
    title: string;
    url: string;
    icon?: JSX.Element;
}

interface EnvironmentNavigation {
    application: {
        applicationName: string;
        env: Record<Exclude<I2PEnvironment, 'model'> | 'local', { applicationId: string; deployedUrl: string }>;
    };
    links: NavigationLink[];
}

interface DriveAccountNavigation {
    application: {
        applicationId: string;
        applicationName: string;
        deployedUrl: string;
    };
    links: NavigationLink[];
    local?: boolean;
}

interface BuildDriveAccountNavigationProps {
    applicationId: string;
    environment: Exclude<I2PEnvironment, 'model'>;
    useLocal?: boolean;
}

export function buildDriveAccountNavigation({
    applicationId,
    environment,
    useLocal,
}: BuildDriveAccountNavigationProps): DriveAccountNavigation[] {
    const localEnvironment = environment === 'dev' && useLocal ? 'local' : environment;

    return routeConfig.map<DriveAccountNavigation>(({ links, application }) => {
        const envConfig = application.env[localEnvironment];
        return {
            application: {
                applicationName: application.applicationName,
                ...envConfig,
            },
            links,
            local: applicationId === envConfig.applicationId,
        };
    });
}

export const showHamburger = routeConfig.length > 1;
