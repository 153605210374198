import { DefaultAnalyticsPage } from '@price-for-profit/analytics';
import driveAccountLogo from 'assets/insight-logo.svg';
import { ITourGuideStep, TourGuideModal } from '@insight2profit/drive-app';

const steps: ITourGuideStep[] = [
    {
        label: 'Welcome to your analytics from INSIGHT2PROFIT!',
        imageUrl: driveAccountLogo,
    },
    {
        label: 'Use the menu on the left to navigate between your different applications and analytics',
        elementRef: '#drive-account-navigation-button',
    },
    {
        label: 'Use the breadcrumb to navigate between your available reports',
        elementRef: '#analytics-report-select',
    },
    // {
    //     label: 'Use the “Share” link on the right to share your analytics reports with other users',
    //     elementRef: '#analytics-share-menu-button',
    // },
    {
        label: 'Use the “Support” link on the right to access help documentation and submit or view support requests',
        elementRef: '#support-menu-button',
    },
    {
        label: 'Use the “Profile” icon on the right to manage your account',
        elementRef: '#user-menu-button',
    },
];

export function AnalyticsResolver() {
    return (
        <>
            <DefaultAnalyticsPage containerHeight='calc(100vh - 75px)' />
            <TourGuideModal uniqueKey='analytics' steps={steps} />
        </>
    );
}
