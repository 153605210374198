import {
    AppLogo,
    AuthenticationRequired,
    CLIENT_ID,
    DemoResolver,
    LoginLayout,
    LoginPage,
    NavigationLayout,
    UnauthorizedPage,
    useService,
    useUser,
} from '@insight2profit/drive-app';
import { AnalyticsSelectReport, ProvideI2pAnalytics } from '@price-for-profit/analytics';
import { useSnackbar } from 'notistack';
import { Navigate, Outlet, useNavigate, useRoutes } from 'react-router-dom';
import { ROUTES } from 'routing';
import clientLogo from 'assets/reladyne-logo-header.png';
import loginClientLogoSrc from 'assets/reladyne-logo.svg';
import { AnalyticsResolver } from 'analytics';
import insightDriveAccountLogo from 'assets/insight-drive-account-logo.svg';
import { Breadcrumbs, Typography } from '@mui/material';

const isProd = process.env.NODE_ENV === 'production';

const headerClientLogo = <AppLogo src={clientLogo} alt='client logo' />;
//const loginClientLogo = <AppLogo src={`${process.env.PUBLIC_URL}/favicon-32x32.png`} alt='client login logo' />;
const loginClientLogo = <AppLogo src={loginClientLogoSrc} alt='logo client login logo' height='80px' />;
const insightLoginLogo = <AppLogo src={insightDriveAccountLogo} alt='insight logo' maxHeight='30px' />;
const driveToProfitLogo = (
    <AppLogo src={`${process.env.PUBLIC_URL}/DRIVEtoPROFIT_Color.png`} alt='drive to profit logo' maxHeight='50px' />
);

export function AppRoutes() {
    return useRoutes([
        {
            element: <Login />,
            children: [
                {
                    path: ROUTES.login,
                    element: (
                        <LoginPage
                            tabTitle='Reladyne account Login'
                            insightLoginLogo={insightLoginLogo}
                            clientLogo={loginClientLogo}
                            loginRedirectRoute={ROUTES.analytics}
                        />
                    ),
                },
            ],
        },
        {
            element: <Private />,
            children: [
                {
                    path: ROUTES.demo,
                    element: <DemoResolver />,
                },
            ],
        },
        {
            element: <PrivateAnalytics />,
            children: [
                {
                    path: ROUTES.analytics,
                    element: <AnalyticsResolver />,
                },
            ],
        },
        {
            path: ROUTES.unauthorized,
            element: <Unauthorized />,
        },
        {
            path: '*',
            element: <Navigate to={ROUTES.login} replace />,
        },
    ]);
}

function Unauthorized() {
    const {
        appConfig: { serviceBaseUrl },
    } = useService();

    return (
        <UnauthorizedPage
            supportPortalLink={'https://insight2profit.atlassian.net/servicedesk/customer/portal/28'}
            logo={headerClientLogo}
            driveLogo={insightLoginLogo}
            serviceBaseUrl={serviceBaseUrl}
        />
    );
}

function Login() {
    const {
        appConfig: { serviceBaseUrl },
    } = useService();

    return (
        <LoginLayout serviceBaseUrl={serviceBaseUrl}>
            <Outlet />
        </LoginLayout>
    );
}
function PrivateAnalytics() {
    const user = useUser();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const {
        appConfig: { appId, serviceBaseUrl },
        eventService,
        authorizationService,
        powerBiService,
    } = useService();
    return (
        <AuthenticationRequired>
            <ProvideI2pAnalytics
                prerequisites={{
                    services: {
                        eventService,
                        authorizationService,
                        powerBiService,
                    },
                    appInfo: {
                        applicationId: appId,
                        clientId: CLIENT_ID,
                        userId: user?.id || '',
                        appName: 'Reladyne Account App',
                    },
                    navigate,
                }}
                options={{
                    snackbar: {
                        enqueueSnackbar,
                    },
                }}
            >
                <NavigationLayout
                    useLocal={!isProd}
                    toolbar={{
                        leftComponent: (
                            <Breadcrumbs aria-label='breadcrumb' color='#aaa'>
                                <Typography color='inherit'>Analytics</Typography>
                                <AnalyticsSelectReport />
                            </Breadcrumbs>
                        ),
                    }}
                    applicationId={appId}
                    logo={headerClientLogo}
                    serviceBaseUrl={serviceBaseUrl}
                    footerLogo={driveToProfitLogo}
                    navigation={{
                        supportPortalLink: 'https://insight2profit.atlassian.net/servicedesk/customer/portal/28',
                    }}
                >
                    <Outlet />
                </NavigationLayout>
            </ProvideI2pAnalytics>
        </AuthenticationRequired>
    );
}

function Private() {
    const {
        appConfig: { serviceBaseUrl, appId },
    } = useService();
    return (
        <AuthenticationRequired>
            <NavigationLayout
                useLocal={!isProd}
                applicationId={appId}
                logo={headerClientLogo}
                serviceBaseUrl={serviceBaseUrl}
                footerLogo={driveToProfitLogo}
                navigation={{
                    supportPortalLink: 'https://insight2profit.atlassian.net/servicedesk/customer/portal/28',
                }}
            >
                <Outlet />
            </NavigationLayout>
        </AuthenticationRequired>
    );
}
